
export default {
  nuxtI18n: false,
  head: {
    title: 'Prismic Preview'
  },
  mounted () {
    this.$prismic.preview()
  }
}
